var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("assignment")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [_vm._v("Simple Table")]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-table", {
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function ({ item }) {
                        return _c(
                          "md-table-row",
                          {},
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "#" } },
                              [_vm._v(_vm._s(item.id))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Name" } },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Country" } },
                              [_vm._v(_vm._s(item.country))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "City" } },
                              [_vm._v(_vm._s(item.city))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Salary" } },
                              [_vm._v(_vm._s(item.salary))]
                            ),
                            _c(
                              "md-table-cell",
                              {
                                class: _vm.getAlignClasses(item),
                                attrs: { "md-label": "Actions" },
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-just-icon",
                                    class: _vm.getClass(item.icon1, item.id),
                                  },
                                  [_c("md-icon", [_vm._v(_vm._s(item.icon1))])],
                                  1
                                ),
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-just-icon",
                                    class: _vm.getClass(item.icon2, item.id),
                                  },
                                  [_c("md-icon", [_vm._v(_vm._s(item.icon2))])],
                                  1
                                ),
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-just-icon",
                                    class: _vm.getClass(item.icon3, item.id),
                                  },
                                  [_c("md-icon", [_vm._v(_vm._s(item.icon3))])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.tableData,
                    callback: function ($$v) {
                      _vm.tableData = $$v
                    },
                    expression: "tableData",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("assignment")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Striped Table with Checkboxes"),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-table", {
                  staticClass: "table-striped",
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function ({ item }) {
                        return _c(
                          "md-table-row",
                          {
                            attrs: {
                              "md-selectable": "multiple",
                              "md-auto-select": "",
                            },
                          },
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "#" } },
                              [_vm._v(_vm._s(item.id))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Product Name" } },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Type" } },
                              [_vm._v(_vm._s(item.type))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Qty" } },
                              [_vm._v(_vm._s(item.qty))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Price" } },
                              [_vm._v(_vm._s(item.price))]
                            ),
                            _c(
                              "md-table-cell",
                              {
                                class: _vm.getAlignClasses(item),
                                attrs: { "md-label": "Amount" },
                              },
                              [_vm._v(_vm._s(item.amount))]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.tableDataStriped,
                    callback: function ($$v) {
                      _vm.tableDataStriped = $$v
                    },
                    expression: "tableDataStriped",
                  },
                }),
                _c("div", { staticClass: "table table-stats table-striped" }, [
                  _c("div", { staticClass: "td-price" }, [
                    _c("div", { staticClass: "td-total" }, [_vm._v(" Total ")]),
                    _c("span", [
                      _c("small", [_vm._v("€")]),
                      _vm._v(" €12,999 "),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("assignment")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Shopping Cart Table"),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-table", {
                  staticClass: "table-shopping",
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function ({ item }) {
                        return _c(
                          "md-table-row",
                          {},
                          [
                            _c("md-table-cell", { attrs: { "md-label": "" } }, [
                              _c("div", { staticClass: "img-container" }, [
                                _c("img", {
                                  attrs: { src: item.image, alt: "products" },
                                }),
                              ]),
                            ]),
                            _c(
                              "md-table-cell",
                              {
                                staticClass: "td-name",
                                attrs: { "md-label": "Product" },
                              },
                              [
                                _c("a", { attrs: { href: "#jacket" } }, [
                                  _vm._v(_vm._s(item.product)),
                                ]),
                                _c("br"),
                                _c("small", [_vm._v(_vm._s(item.category))]),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Color" } },
                              [_vm._v(_vm._s(item.color))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Size" } },
                              [_vm._v(_vm._s(item.size))]
                            ),
                            _c(
                              "md-table-cell",
                              {
                                staticClass: "td-number",
                                attrs: { "md-label": "Price" },
                              },
                              [
                                _c("small", [_vm._v("€")]),
                                _vm._v(" " + _vm._s(item.price) + " "),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              {
                                staticClass: "td-number",
                                attrs: { "md-label": "Qty" },
                              },
                              [
                                _vm._v(" " + _vm._s(item.qty) + " "),
                                _c(
                                  "div",
                                  { staticClass: "md-group" },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-round md-info md-just-icon",
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.increaseQuantity(item)
                                          },
                                        },
                                      },
                                      [_c("md-icon", [_vm._v("add")])],
                                      1
                                    ),
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-round md-info md-just-icon",
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.decreaseQuantity(item)
                                          },
                                        },
                                      },
                                      [_c("md-icon", [_vm._v("remove")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              {
                                staticClass: "td-number",
                                attrs: { "md-label": "Amount" },
                              },
                              [
                                _c("small", [_vm._v("€")]),
                                _vm._v(" " + _vm._s(item.amount) + " "),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-just-icon md-round md-simple",
                                  },
                                  [_c("md-icon", [_vm._v("close")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.shoppingCartTable,
                    callback: function ($$v) {
                      _vm.shoppingCartTable = $$v
                    },
                    expression: "shoppingCartTable",
                  },
                }),
                _c("div", { staticClass: "table table-stats" }, [
                  _c("div", { staticClass: "td-price" }, [
                    _c("div", { staticClass: "td-total" }, [_vm._v(" Total ")]),
                    _c("span", [
                      _c("small", [_vm._v("€")]),
                      _vm._v(" " + _vm._s(_vm.shoppingTotal) + " "),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "md-button",
                        { staticClass: "md-info md-round" },
                        [
                          _vm._v(" Complete Purchase "),
                          _c("md-icon", [_vm._v("keyboard_arrow_right")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }